.swiper {
  position: relative;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  aspect-ratio: 1.5;

  div[class*='swiper-pagination'] {
    position: absolute;
    bottom: 95%;
    display: flex;
    justify-content: space-between;
    width: 98%;
    margin-block: auto;
  }

  span[class*='swiper-pagination-bullet'] {
    flex: auto;
    height: 10px;
    background: linear-gradient(to top, transparent 25%, #ffffffe6 25% 65%, transparent 65%);
    border-radius: 50px;
  }

  h3 {
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 2;
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    color: #1a1a1a;
    background: #fff;
    border-radius: 5px;
    padding: 3px;
    pointer-events: none;
  }
}

.sliderItem {
  position: relative;
  width: 100%;
  height: 100%;
}

span.id {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #fff;
  background: #f7505ce6;
  border-radius: 5px;
  padding: 3px 5px;
  z-index: 1;
  height: max-content;
  width: max-content;
}

.seeMore {
  width: 100%;
  height: 100%;
  display: none;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  background: #00000077;
  z-index: 1;
  cursor: pointer;

  span,
  img {
    color: #fff;
    user-select: none;
    pointer-events: none;
  }

  @media (max-width: 768px) {
    display: flex;
  }
}

.heartIcon {
  transform: scale(1.2);
  position: absolute;
  top: 20px;
  right: 8px;
  z-index: 1;
  cursor: pointer;
  transition: 0.1s ease-in-out;
  user-select: none;

  &:hover {
    transform: scale(1.3);
  }
}